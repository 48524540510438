import React, { Fragment, useEffect, useState } from 'react';
import API from "../../utils/API";
import _ from "lodash";
import {Input, Row, Col} from "reactstrap";
import Breadcrumb from '../common/breadcrumb';
import Stepper from 'react-stepper-horizontal';
import { useForm } from 'react-hook-form';
import { Card, CardBody } from 'reactstrap';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Dropzone from 'react-dropzone-uploader';
import { Redirect } from 'react-router';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';

const WorkOrderForProjectCreate = (props) =>  {
    const {register} = useForm();
    const [workOrderName, setWorkOrderName] = useState("Werkbon");
    const [workOrderDescription, setWorkOrderDescription] = useState("Werkbon");
    const [workOrderItems, setWorkOrderItems] = useState([]);
    const [project, setProject] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [workOrderTemplates, setWorkOrderTemplates] = useState([]);
    const [selectedWorkOrderTemplate, setSelectedWorkOrderTemplate] = useState(null);
    const [workOrderScans, setWorkOrderScans] = useState([]);
    const [workOrderPreviews, setWorkOrderPreviews] = useState([]);
    const [showCameraForPreviewPhotos, setShowCameraForPreviewPhotos] = useState(false);
    const [showCameraForScans, setShowCameraForScans] = useState(false);

    const [isBusyWithAddingWorkOrder, setIsBusyWithAddingWorkOrder] = useState(false);
    const [isSuccessfulAddedToProject, setIsSuccessfulAddedToProject] = useState(false);
    
    const sections = [
        {title: "Voorfoto's"},
        {title: "Werkbongegevens"},
        {title: "Extra foto's/bestanden"},
        {title: "Werkbon toevoegen"}
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsBusyWithAddingWorkOrder(true);
        const formData = {
            selectedProjectId : project.id,
            workOrderName,
            workOrderDescription,
            workOrderItems,
            workOrderScans,
            workOrderPreviews
        };

        API.post('workorder', formData).then(function() {
            setIsSuccessfulAddedToProject(true);
        });
    }

    const next = () => setCurrentPage((prev) => {
        if (prev === 0) {
            if (project !== null) {
                return prev + 1;
            } else {
                return prev;
            }
        }
        return prev + 1;
    });
    const prev = () => setCurrentPage((prev) => prev - 1);

    const loadWorkOrderTemplates = () => {
        API.get('workordertemplates').then(function(response) {
            setWorkOrderTemplates(response.data);
        });
    };

    const handleWorkOrderItem = event => {
        let items = [...workOrderItems];
        let found = false;
        items.map((item, index) => {
            if (item.id == event.target.name) {
                items[index] = {
                    id: event.target.name,
                    value: event.target.value
                };
                found = true;
            }
        });
        if (!found) {
            items = [...items, {
                id: event.target.name,
                value: event.target.value
            }];
        }
        
        setWorkOrderItems(items);
    }

    const onChangeWorkOrderTemplate = (event) => {
        const workOrderTemplateId = event.target.value;
        workOrderTemplates.map((workOrderTemplate) => {
            if (workOrderTemplateId == workOrderTemplate.id) {
                setSelectedWorkOrderTemplate(workOrderTemplate);
            }
        });
        
    };

    const handleTakePhoto = (dataUri) => {
        setWorkOrderScans([...workOrderScans, dataUri]);
    };

    const handleTakePhotoForWorkOrderPreview = (dataUri) => {
        setWorkOrderPreviews([...workOrderPreviews, dataUri]);
    };

    const readPreviewImage = (id, file) => {
        getBase64(file, (result) => {
            setWorkOrderPreviews([...workOrderPreviews, result]);
        });
    };

    const readScan = (id, file) => {
        getBase64(file, (result) => {
            setWorkOrderScans([...workOrderScans, result]);
        });
    };

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {cb(reader.result)};
        reader.onerror = function (error) {console.log('Error: ', error);};
    };

    const handlePreviewImages = ({ meta, file }, status) => {
        if (meta.status === "done") {
            readPreviewImage(meta.id, file);
        }
    };

    const handleScans = ({ meta, file }, status) => {
        if (meta.status === "done") {
            readScan(meta.id, file);
        }
    };

    useEffect(() => {
        if (currentPage == 0) {
            API.get('project/' + props.match.params.id).then(function(response) {
                setProject(response.data);
            });
        }
        if (currentPage == 1) {
            loadWorkOrderTemplates();
        }
    }, [currentPage]);


    const getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject))
            })
        })
    }

    const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
        return (
            <label className={files.length > 0 ? 'dzu-inputLabelWithFiles' : 'dzu-inputLabel'}>
                {files.length > 0 ? (
                    <>
                        <span className="icofont icofont-2x icofont-cloud-upload"></span> Uploaden van extra voorfoto's
                    </>
                ) : (
                    <div>
                        <h1 className="icofont icofont-4x icofont-cloud-upload"></h1>
                        <br />
                        <p>Opladen van voorfoto\'s</p>
                    </div>
                )}
                <input
                    style={{ display: 'none' }}
                    type="file"
                    accept={accept}
                    multiple
                    onChange={e => {
                        getFilesFromEvent(e).then(chosenFiles => {
                            onFiles(chosenFiles)
                        })
                    }}
                />
            </label>
        )
    }

    return (
        <Fragment>
            {isSuccessfulAddedToProject === true && project !== null ? (
                <Redirect to={'/project/detail/' + project.id} />
            ) : null}
            {project !== null ? (
                <Breadcrumb parent={'Project ' + project.name} parentLink={'/project/detail/' + project.id}>
                    Werkbon aanmaken
                </Breadcrumb>
            ) : null}
            <div className="container-fluid">
                <div className="edit-profile">
                

                    <Row>
                        <Col lg={2} md={4} sm={12} xs={12}>
                            <Card>
                                <CardBody>
                                    <h4>{project.projectNumber} - {project.name}</h4>
                                    <p dangerouslySetInnerHTML={{__html: project.description}}></p>
                                    <p>
                                        <strong>Locatie van werken</strong><br />
                                        {project.street} {project.houseNumber} {project.bus}<br />
                                        {project.postalCode} {project.city}<br />
                                        {project.country ? project.country.name : ''}
                                    </p>
                                    <p>
                                        <strong>Contactpersoon</strong><br />
                                        {project.customer ? project.customer.firstName + ' ' + project.customer.lastName : ''}<br />
                                        {project.phone}<br />
                                        {project.mobile}<br />
                                        {project.primaryEmail}
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={10} md={8} sm={12} xs={12}>
                        <Card>
                            <CardBody>
                                <Stepper
                                    steps={sections}
                                    activeStep={currentPage}
                                    activeColor={'orange'}
                                    defaultBarColor={'grey'}
                                    completeColor={'orange'}
                                    completeBarColor={'orange'}
                                />

                                {currentPage === 0 && (
                                    <>
                                        <Row className='mt-5'>
                                            <Col lg={12}>
                                                <div className="btn-group btn-group-square" role="group">
                                                    <button className={showCameraForPreviewPhotos ? ('btn btn-outline-primary') : ('btn btn-primary')} onClick={() => setShowCameraForPreviewPhotos(false)} type="button">Bestand toevoegen</button>
                                                    <button className={showCameraForPreviewPhotos ? ('btn btn-primary') : ('btn btn-outline-primary')} onClick={() => setShowCameraForPreviewPhotos(true)} type="button">Foto maken</button>
                                                </div>
                                            </Col>
                                        </Row>

                                        {showCameraForPreviewPhotos === true ? (
                                            <Row className={'mt-5'}>
                                                <Col lg={12}>
                                                    <Camera idealFacingMode={FACING_MODES.ENVIRONMENT} isImageMirror={false} onTakePhoto = { (dataUri) => { handleTakePhotoForWorkOrderPreview(dataUri); } } />
                                                    <Row>
                                                        {workOrderPreviews.length > 0 ? (
                                                            workOrderPreviews.map(workOrderPreview => {
                                                                return <Col md={3}><img src={workOrderPreview} style={{width: '90%',padding:'1%'}} /></Col>
                                                            })
                                                        ) : null}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row className={'mt-5'}>
                                                <Col lg={12}>
                                                    <Dropzone
                                                        onChangeStatus={e => {
                                                            handlePreviewImages(e)
                                                        }}
                                                        accept={'image/*'}
                                                        maxFiles={20}
                                                        multiple={true}
                                                        canCancel={false}
                                                        canRemove={false}
                                                        InputComponent={InputChooseFile}
                                                        getFilesFromEvent={getFilesFromEvent}
                                                        styles={{
                                                            previewImage: { minHeight: 150, maxHeight: 150 },
                                                            dropzone: { minHeight: 400, maxHeight: 500 }
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        )}

                                                                    
                                        <Row className='mt-5'>
                                            <Col className='col-lg-12 text-right'>
                                                <button className={'btn btn-primary'} onClick={next} style={{marginRight: '25px'}}>Volgende</button>
                                            </Col>
                                        </Row>
                                    </>
                                )}

                    {currentPage === 1 && (
                        <>
                            <Row className="mt-5">
                                <Col lg='10' className='offset-lg-1'>
                                    <h2>Selecteer sjabloon van werkbon</h2>
                                </Col>
                                <Col lg='10' className="offset-lg-1">
                                    <div>
                                        {workOrderTemplates.map((workOrderTemplate) => {
                                            return (<><Input type='radio' name='selectedWorkOrderTemplateId' value={workOrderTemplate.id} onChange={onChangeWorkOrderTemplate} /> {workOrderTemplate.name} <br /></>)
                                        })}
                                    </div>
                                </Col>
                            </Row>
                            {selectedWorkOrderTemplate ? (
                                <>
                                    <Row className="mt-5">
                                        <Col lg='10' className='offset-lg-1'>
                                            <h2>Werkbongegevens:</h2>
                                        </Col>
                                    </Row>
                                    <div className={'form-group row'}>
                                        <label className={'col-xl-12'}>
                                            Naam:
                                        </label>
                                        <div className={'col-xl-12'}>
                                            <input type={'text'} className={'form-control'} name="name" ref={register} value={workOrderName} onChange={event => setWorkOrderName(event.target.value)} />
                                        </div>
                                    </div>
                                    <div className={'form-group row'}>
                                        <label className={'col-xl-12'}>
                                            Beschrijving:
                                        </label>
                                        <div className={'col-xl-12'}>
                                            <input type={'text'} className={'form-control'} name="description" ref={register} value={workOrderDescription} onChange={event => setWorkOrderDescription(event.target.value)} />
                                        </div>
                                    </div>
                                    {selectedWorkOrderTemplate.items.map((item) => {
                                        return (
                                            <div className={'form-group row'}>
                                                <label className={'col-xl-12'}>
                                                    {item.question}
                                                </label>
                                                <div className={'col-xl-12'}>
                                                    {item.type.name === 'text' ? (
                                                        <textarea type={'text'} name={item.id} onChange={(event) => handleWorkOrderItem(event)} className={'form-control'} />
                                                    ) : null}
                                                    {item.type.name === 'multiplechoice' ? (
                                                        item.options.map((option) => {
                                                            return <label className={'form-control'}><input type={'radio'} name={item.id} value={option.name} onChange={(event) => handleWorkOrderItem(event)} /> {option.name}</label>
                                                        })
                                                    ) : null}
                                                </div>
                                            </div>
                                        )
                                    })}
                                    
                                </>
                            ) : null}
                            <Row className='mt-5'>
                                <Col className='col-lg-12 text-right'>
                                    <button className={'btn btn-primary'} onClick={prev} style={{marginRight: '25px'}}>Vorige</button>
                                    <button className={'btn btn-primary'} onClick={next} style={{marginRight: '25px'}}>Volgende</button>
                                </Col>
                            </Row>
                        </>
                    )}  

                    {currentPage === 2 && (
                        <>
                            <Row className='mt-5'>
                                <Col lg={12}>
                                    <div className="btn-group btn-group-square" role="group">
                                        <button className={showCameraForScans ? ('btn btn-outline-primary') : ('btn btn-primary')} onClick={() => setShowCameraForScans(false)} type="button">Bestand toevoegen</button>
                                        <button className={showCameraForScans ? ('btn btn-primary') : ('btn btn-outline-primary')} onClick={() => setShowCameraForScans(true)} type="button">Foto maken</button>
                                    </div>
                                </Col>
                            </Row>
                            {showCameraForScans === true ? (
                                <Camera idealFacingMode={FACING_MODES.ENVIRONMENT} isImageMirror={false} onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } } />
                            ) : (
                                <Row className={'mt-5'}>
                                    <Col lg={12}>
                                        <Dropzone
                                            onChangeStatus={e => {
                                                handleScans(e)
                                            }}
                                            accept={'image/*'}
                                            maxFiles={20}
                                            multiple={true}
                                            canCancel={false}
                                            canRemove={false}
                                            InputComponent={InputChooseFile}
                                            getFilesFromEvent={getFilesFromEvent}
                                            styles={{
                                                previewImage: { minHeight: 150, maxHeight: 150 },
                                                dropzone: { minHeight: 400, maxHeight: 500 }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            )}
                            <Row className='mt-5'>
                                <Col className='col-lg-12 text-right'>
                                    <button className={'btn btn-primary'} onClick={prev} style={{marginRight: '25px'}}>Vorige</button>
                                    <button className={'btn btn-primary'} onClick={next}>Volgende</button>
                                </Col>
                            </Row>
                        </>
                    )}

                    {currentPage === 3 && (
                        <>
                            <Row>
                                <Col md={12}>
                                    <h2>Voorfoto's</h2>
                                </Col>
                            </Row>
                            <Row>    
                                {workOrderPreviews.map(workOrderPreview => {
                                    return <Col md={3}><img src={workOrderPreview} style={{width: '90%',padding:'1%'}} /></Col>
                                })}
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <h2>Gegevens werkbon</h2>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={3}>Naam:</Col>
                                <Col md={9}>{workOrderName}</Col>
                            </Row>
                            <Row>
                                <Col md={3}>Beschrijving:</Col>
                                <Col md={9}>{workOrderDescription}</Col>
                            </Row>

                            {selectedWorkOrderTemplate.items.map((item) => {
                                return (
                                    <div className={'form-group row'}>
                                        <label className={'col-xl-6'}>
                                            {item.question}
                                        </label>
                                        <div className={'col-xl-6'}>
                                            {workOrderItems.map((workOrderItem) => {
                                                return <>
                                                    {workOrderItem.id == item.id ? (<blockquote>{workOrderItem.value}</blockquote>) : null}
                                                </>
                                                                
                                            })}
                                        </div>
                                    </div>
                                )
                            })}

                            <h2>Extra foto's</h2>

                            <Row>    
                                {workOrderScans.map(workOrderScan => {
                                    return <Col md={3}><img src={workOrderScan} style={{width: '90%',padding:'1%'}} /></Col>
                                })}
                            </Row>


                            <Row className='mt-5'>
                                <Col className='col-lg-12 text-right'>
                                {isBusyWithAddingWorkOrder === true ? (
                                    <>
                                        <button className={'btn btn-secondary'}>Bezig ...</button>
                                        
                                    </>
                                ) : (
                                    <>
                                        <button className={'btn btn-primary'} onClick={prev}>Vorige</button>
                                        <button className={'btn btn-primary'} onClick={handleSubmit}>Werkbon toevoegen</button>
                                    </>
                                )}
                                </Col>
                            </Row>
                            
                        </>
                    )}
</CardBody>
</Card>
                        </Col>
                    </Row>
                </div>
            </div>
            
            
        </Fragment>
    );
};

export default WorkOrderForProjectCreate;
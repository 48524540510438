import React, { Fragment, useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import Loader from '../common/loader';
import useReactTable from '../../hooks/useReactTable';
import {Link} from "react-router-dom";
import {Card, CardBody, Col, Input, Row} from "reactstrap";

const SettingsOverview = () =>  {

    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [settings, setSettings] = useState([]);
    const [pages, setPages] = useState(-1);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = useMemo(() => [{
        Header : 'Key',
        accessor : 'key',
        headerClassName : 'text-left',
        Cell: row => {
            return row.row.key
        }
    },
    {
        Header : 'Value',
        accessor : 'value',
        headerClassName : 'text-left',
        Cell: row => {
            return row.row.value
        }
    }]);

    useEffect(() => {
        loadCurrentUser();
        globalSearch();
    }, []);

    const loadCurrentUser = () => {
        API.get('myuser').then(function(response) {
            if (response.data !== undefined) {
                setCurrentUser(response.data[0]);
                setIsLoading(false);
            }
        });
    }

    const globalSearch = () => {
        API.get('settings').then(function(response) {
            setSettings(response.data);
            setIsLoading(false);
        });
    };

    return (
        <Fragment>
            {isLoading ? (
                <Loader />
            ) : (
                <Fragment>
                    <Row>
                        <Col sm={12}>
                            <Breadcrumb>Projecten</Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm={12}>
                                            <h2>Overzicht van applicatie-eigenschappen</h2>
                                        </Col>
                                    </Row>
                                    {currentUser !== null && (currentUser.userRole === 'ADMIN' ||  currentUser.userRole === 'EMPLOYER' || currentUser.userRole === 'EMPLOYEE') ? (
                                        <>
                                            <Row className='mt-3'>
                                                <Col sm={12}>
                                                    <ReactTable 
                                                        data={settings}
                                                        columns={columns}
                                                        minRows={0}
                                                        defaultPageSize={10}
                                                        className={"-striped -highlight"}
                                                        showPagination={true}
                                                        previousText={'Vorige'}
                                                        nextText={'Volgende'}
                                                        pageText={'Pagina'}
                                                        rowsText={'rijen'}
                                                        ofText={'van'}
                                                        getTheadThProps={getTheadThProps}
                                                        getTdProps={getTdProps}
                                                        getTrProps={getTrProps}
                                                        getPaginationProps={getPaginationProps}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                        
                                    ) : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Fragment>
            )}
        </Fragment>
    );
};

export default SettingsOverview;
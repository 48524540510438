import React, {useState, Fragment, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import API from "../../../utils/API";
import logo_compact from "../../../assets/images/logo-pouillon.png";
import logo_planaxis from "../../../assets/images/logo_planaxis.png";
import logo_windowrepair from "../../../assets/images/logo_windowrepair.png";
import { Add, AccountTree, Assignment, Description, Group, Home, Schedule, Settings, Person, MeetingRoom, CalendarToday, PermContactCalendarSharp } from '@material-ui/icons';
import { Col, Row } from 'reactstrap';

const Header = () => {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState('EMPLOYEE');
  const history = useHistory();

  useEffect(() => {
    API.get('/myuser').then(function(response) {
      setUser(response.data[0]);
      setUserRole(response.data[0].userRole);
    });
  }, []);

  const logOut = (event) => { 
    event.preventDefault();
    localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN);
    history.push(`${process.env.PUBLIC_URL}/login`);
  };

  return (
    <Fragment>
      <div className="page-main-header" >
        <Row className="main-header-right">
          <Col className="col-md-12 p-0 d-none d-lg-block d-xl-block float-right">
            {userRole === 'ADMIN' || userRole === 'EMPLOYER' ? (
              <Row>
                <Col lg={8}>
                <div className='d-flex flex-row'>
                <div className='p-1'>
                  <Link to="/dashboard/default">
                    {window.location.host.includes('planaxis') ? (
                      <img src={logo_planaxis} alt="" style={{height: '50px'}} />
                    ) : null}
                    {window.location.host.includes('windowrepair') ? (
                      <img src={logo_windowrepair} alt="" style={{height: '40px'}} />
                    ) : null}
                    {window.location.host.includes('pouillon') ? (
                      <img src={logo_compact} alt="" style={{height: '40px'}} />
                    ) : null}
                    {window.location.host.includes('localhost') ? (
                      <img src={logo_windowrepair} alt="" style={{height: '40px'}} />
                    ) : null}
                  </Link>
                </div>
                <div className='p-3 pt-2'><a href="/" title={'Dashboard'}><Home /></a></div>
                <div className='p-3'><Link title={'Projecten'} to={'/projects/overview'}> Projecten</Link></div>
                <div className='p-3'><Link title={'Planning'} to={'/planning'}> Planning</Link></div>
                <div className='p-3'><Link title={'Taken'} to={'/taskmanagement/overview'}>Taken</Link></div>
                <div className='p-3'><Link title={'Prestaties'} to={'/timesheets/overview'}>Tijdsregistraties</Link></div>
                <div className='p-3'><Link title={'Documenten'} to={'/documents'}> Documenten</Link></div>
                <div className='p-3'><Link title={'Gebruikers'} to={"/users/overview"}> Gebruikers</Link></div>
                <div className='p-3'><Link title={'Werkbon toevoegen'} to={'/workorder/create'}>Werkbon toevoegen</Link></div>
                </div>
                </Col>
                <Col lg={4}>
                <div className='d-flex flex-row text-right float-right'>
                  <div className='p-3'><Link title={'Instellingen'} to={"/settings"}> <Settings /></Link></div>
                  {user !== null && user !== undefined ? (
                      <>
                        <div className='p-3'><Link title={'Mijn profiel'} to={'/user/' + user.id + '/edit'}><Person /><br /></Link></div>
                        <div className='p-3'><a href="#" title={'Uitloggen'} onClick={logOut}><MeetingRoom /><br /></a></div>
                      </>
                    ) : null}
                  
                  </div>
                </Col>
              </Row>       
            ) : (
              <Row>
                <Col lg={8}>
                <div className='d-flex flex-row'>
                <div className='p-1'><Link to="/dashboard/default"><img src={logo_compact} alt="" style={{height: '40px'}} /></Link></div>
                <div className='p-3 pt-2'><a href="/" title={'Dashboard'}><Home /></a></div>
                <div className='p-3'><Link title={'Projecten'} to={'/projects/overview'}> Projecten</Link></div>
                <div className='p-3'><Link title={'Taken'} to={'/tasks/overview'}>Taken</Link></div>
                <div className='p-3'><Link title={'Prestaties'} to={'/timesheets/overview'}>Tijdsregistraties</Link></div>
                <div className='p-3'><Link title={'Werkbon toevoegen'} to={'/workorder/create'}>Werkbon toevoegen</Link></div>
                </div>
                </Col>
                <Col lg={4}>
                <div className='d-flex flex-row text-right float-right'>
                  {user !== null && user !== undefined ? (
                      <>
                        <div className='p-3'><Link title={'Mijn profiel'} to={'/user/' + user.id + '/edit'}><Person /><br /></Link></div>
                        <div className='p-3'><a href="#" title={'Uitloggen'} onClick={logOut}><MeetingRoom /><br /></a></div>
                      </>
                    ) : null}
                  
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          <Col className="col-md-12 p-0 d-none d-md-block d-lg-none float-right">
            {userRole === 'ADMIN' || userRole === 'EMPLOYER' ? (
              <Row>
                <Col md={8}>
                <div className='d-flex flex-row'>
                <div className='p-1'><Link to="/dashboard/default"><img src={logo_compact} alt="" style={{height: '40px'}} /></Link></div>
                <div className='p-3 pt-2'><a href="/" title={'Dashboard'}><Home /></a></div>
                <div className='p-3'><Link title={'Projecten'} to={'/projects/overview'}> <AccountTree /></Link></div>
                <div className='p-3'><Link title={'Planning'} to={'/planning'}> <PermContactCalendarSharp /></Link></div>
                <div className='p-3'><Link title={'Taken'} to={'/taskmanagement/overview'}><Schedule /></Link></div>
                <div className='p-3'><Link title={'Prestaties'} to={'/timesheets/overview'}><CalendarToday /></Link></div>
                <div className='p-3'><Link title={'Documenten'} to={'/documents'}> <Description /></Link></div>
                <div className='p-3'><Link title={'Gebruikers'} to={"/users/overview"}> <Group /></Link></div>
                <div className='p-3'><Link title={'Werkbon toevoegen'} to={'/workorder/create'}><Add /></Link></div>
                </div>
                </Col>
                <Col md={4}>
                <div className='d-flex flex-row text-right float-right'>
                  <div className='p-3'><Link title={'Instellingen'} to={"/settings"}> <Settings /></Link></div>
                  {user !== null && user !== undefined ? (
                      <>
                        <div className='p-3'><Link title={'Mijn profiel'} to={'/user/' + user.id + '/edit'}><Person /><br /></Link></div>
                        <div className='p-3'><a href="#" title={'Uitloggen'} onClick={logOut}><MeetingRoom /><br /></a></div>
                      </>
                    ) : null}
                  
                  </div>
                </Col>
              </Row>       
            ) : (
              <Row>
                <Col md={8}>
                <div className='d-flex flex-row'>
                <div className='p-1'><Link to="/dashboard/default"><img src={logo_compact} alt="" style={{height: '40px'}} /></Link></div>
                <div className='p-3 pt-2'><a href="/" title={'Dashboard'}><Home /></a></div>
                <div className='p-3'><Link title={'Projecten'} to={'/projects/overview'}> <AccountTree /></Link></div>
                <div className='p-3'><Link title={'Taken'} to={'/taskmanagement/overview'}><Schedule /></Link></div>
                <div className='p-3'><Link title={'Prestaties'} to={'/timesheets/overview'}><CalendarToday /></Link></div>
                <div className='p-3'><Link title={'Werkbon toevoegen'} to={'/workorder/create'}><Add /></Link></div>
                </div>
                </Col>
                <Col md={4}>
                <div className='d-flex flex-row text-right float-right'>
                  {user !== null && user !== undefined ? (
                      <>
                        <div className='p-3'><Link title={'Mijn profiel'} to={'/user/' + user.id + '/edit'}><Person /><br /></Link></div>
                        <div className='p-3'><a href="#" title={'Uitloggen'} onClick={logOut}><MeetingRoom /><br /></a></div>
                      </>
                    ) : null}
                  
                  </div>
                </Col>
              </Row> 
            )}
          </Col>
          <Col className="col-md-10 p-0 d-none d-block d-sm-block d-md-none">
            {userRole === 'ADMIN' || userRole === 'EMPLOYER' ? (
              <Row>
              <Col xs={8}>
              <div className='d-flex flex-row'>
              <div className='p-3 pt-2'><a href="/" title={'Dashboard'}><Home /></a></div>
              <div className='p-3'><Link title={'Projecten'} to={'/projects/overview'}> <AccountTree /></Link></div>
              <div className='p-3'><Link title={'Planning'} to={'/planning'}> <PermContactCalendarSharp /></Link></div>
              <div className='p-3'><Link title={'Taken'} to={'/taskmanagement/overview'}><Schedule /></Link></div>
              <div className='p-3'><Link title={'Prestaties'} to={'/timesheets/overview'}><CalendarToday /></Link></div>
              <div className='p-3'><Link title={'Documenten'} to={'/documents'}> <Description /></Link></div>
              <div className='p-3'><Link title={'Gebruikers'} to={"/users/overview"}> <Group /></Link></div>
              <div className='p-3'><Link title={'Werkbon toevoegen'} to={'/workorder/create'}><Add /></Link></div>
              </div>
              </Col>
              <Col xs={4}>
              <div className='d-flex flex-row text-right float-right'>
                <div className='p-3'><Link title={'Instellingen'} to={"/settings"}> <Settings /></Link></div>
                {user !== null && user !== undefined ? (
                    <>
                      <div className='p-3'><Link title={'Mijn profiel'} to={'/user/' + user.id + '/edit'}><Person /><br /></Link></div>
                      <div className='p-3'><a href="#" title={'Uitloggen'} onClick={logOut}><MeetingRoom /><br /></a></div>
                    </>
                  ) : null}
                
                </div>
              </Col>
            </Row>  
            ) : (
              <Row>
                <Col xs={8}>
                <div className='d-flex flex-row'>
                <div className='p-1'><Link to="/dashboard/default"><img src={logo_compact} alt="" style={{height: '40px'}} /></Link></div>
                <div className='p-3 pt-2'><a href="/" title={'Dashboard'}><Home /></a></div>
                <div className='p-3'><Link title={'Projecten'} to={'/projects/overview'}> <AccountTree /></Link></div>
                <div className='p-3'><Link title={'Taken'} to={'/taskmanagement/overview'}><Schedule /></Link></div>
                <div className='p-3'><Link title={'Prestaties'} to={'/timesheets/overview'}><CalendarToday /></Link></div>
                <div className='p-3'><Link title={'Werkbon toevoegen'} to={'/workorder/create'}><Add /></Link></div>
                </div>
                </Col>
                <Col xs={4}>
                <div className='d-flex flex-row text-right float-right'>
                  {user !== null && user !== undefined ? (
                      <>
                        <div className='p-3'><Link title={'Mijn profiel'} to={'/user/' + user.id + '/edit'}><Person /><br /></Link></div>
                        <div className='p-3'><a href="#" title={'Uitloggen'} onClick={logOut}><MeetingRoom /><br /></a></div>
                      </>
                    ) : null}
                  
                  </div>
                </Col>
              </Row> 
            )}
          </Col>
        </Row>
      </div>
    </Fragment>
  )
};
export default Header;
import API from "../utils/API";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import APIUnauthorised from "../utils/APIUnauthorised";

const useReactTable = () => {


  const getTheadThProps = () => ({
    style: {
        fontWeight: '600',
        height: '50px',
        color: process.env.REACT_APP_STYLING_CARD_BACKGROUND_COLOR,
        backgroundColor: process.env.REACT_APP_STYLING_BODY_BACKGROUND_COLOR,
        borderLeft: '0px',
        borderRight: '0px',
        textTransform: 'uppercase'
    },
  });
  const getTdProps = () => ({
    style: {
        height: '50px',
        color: process.env.REACT_APP_STYLING_CARD_FONT_COLOR + ' !important',
        backgroundColor: process.env.REACT_APP_STYLING_BODY_BACKGROUND_COLOR,
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        borderBottom: 0
    },
  });
  const getTrProps = () => ({
    style: {
        height: '50px',
        color: process.env.REACT_APP_STYLING_CARD_FONT_COLOR,
        backgroundColor: process.env.REACT_APP_STYLING_BODY_BACKGROUND_COLOR,
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        borderBottom: 0
    }
  });
  
  const getPaginationProps= () => ({
    style: {
      color: process.env.REACT_APP_STYLING_CARD_BACKGROUND_COLOR,
        backgroundColor: process.env.REACT_APP_STYLING_BODY_BACKGROUND_COLOR,
    } 
  });

    return {
      getTheadThProps: getTheadThProps,
      getTdProps: getTdProps,
      getTrProps: getTrProps,
      getPaginationProps: getPaginationProps
    };
}

export default useReactTable;
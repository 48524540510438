import React, { Fragment } from 'react';
import '../../assets/css/reacttable_pouillon.css';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import cloneDeep from 'lodash/cloneDeep';
import Dropzone from "react-dropzone-uploader";
import Masonry from "react-masonry-css";
import _ from "lodash";
import Loader from "../common/loader";
import {Col, Row} from "reactstrap";
import Camera from 'react-html5-camera-photo';
import {v4 as uuidv4} from 'uuid';
import WorkOrderProjectInformation from './workOrderProjectInformation';
import WorkOrderTasks from './workOrderTasks';
import WorkOrderPhotos from './workOrderPhotos';

class WorkOrderDetail extends React.Component {

    constructor(props) {
        super(props);



        this.state = {
            previewImageLoading: false,
            reviewImageLoading: false,
            workOrder : null,
            editView : false,
            uploadedPreviewImages: [],
            uploadedReviewImages: []
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        let me = this;
        API.get('workorder/' + id).then(function(response) {
            me.setState({workOrder: response.data});
        });

    }

    reloadWorkOrder = () => {
        const { id } = this.props.match.params;
        let me = this;
        API.get('workorder/' + id).then(function(response) {
            me.setState({workOrder: response.data}, function() {
                me.setState({previewImageLoading: false});
            });
        });
    };

    openEditView = () => {
        this.setState({editView: true});
    };

    closeEditView = () => {
        this.setState({editView: false});
    };

    changeName = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({workOrder: { ...prevState.workOrder, name : value }}));
    };

    changeDescription = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({workOrder: { ...prevState.workOrder, description : value }}));
    };

    changeAnswerOfQuestion = (event, questionId) => {
        let value = event.target.value;
        let workOrder = cloneDeep(this.state.workOrder);
        workOrder.items.map((item, index) => {
            if (item.id === questionId) {
                item.answer = value;
            }
        });
        this.setState({workOrder: workOrder});
    };

    updateWorkOrder = (event) => {
        event.preventDefault();
        API.put('/workorder/' + this.state.workOrder.id, this.state.workOrder).then(function(response) {
            console.log(response);
        }).catch(function(response) {
            console.log(response);
        });
    };

    handleTakePhoto = (dataUri) => {
        console.log(dataUri.substr(dataUri.indexOf(";") + 1 ));
        let decoded = atob(dataUri.substr(dataUri.indexOf(";") + 8 )); 

        let newFile = {
            id: uuidv4(),
            fileName : 'cameraphoto',
            mimeType : 'image/png',
            fileSize : decoded.length,
            file : dataUri
        };
        let files = [...this.state.uploadedPreviewImages];
        files.push(newFile);
        this.setState({uploadedPreviewImages: files}, function() {
            this.setState({previewImageLoading: false});
        });

        //setWorkOrderScans([...workOrderScans, dataUri]);
    };

    readPreviewImage(id, file) {
        let newFile = {
            id,
            fileName : file.name,
            mimeType : file.type,
            fileSize : file.size,
            file : file.file
        };
        let files = [...this.state.uploadedPreviewImages];
        files.push(newFile);
        this.setState({uploadedPreviewImages: files}, function() {
            this.setState({previewImageLoading: false});
        });
    };

    readReviewImage(id, file) {
        const reader = new FileReader();
        var MAX_WIDTH  = 400;
        var MAX_HEIGHT = 300;

        reader.onload = event => {
            let origImg = new Image();
            origImg.src = event.target.result;
            origImg.onload = (event) => {
                let width  = event.target.width;
                let height = event.target.height;
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                let canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(origImg, 0, 0, width, height);
                let newFile = {
                    id,
                    fileName : file.name,
                    mimeType : file.type,
                    fileSize : file.size,
                    file : canvas.toDataURL()
                };
                let files = [...this.state.uploadedReviewImages];
                files.push(newFile);
                this.setState({uploadedReviewImages: files});
            }


        };
        reader.readAsDataURL(file);
    };

    handlePreviewImages = ({ meta, file }, status) => {
        let me = this;
        if (meta.status === "done") {
            me.readPreviewImage(meta.id, file);
        }
    };

    handleReviewImages = ({ meta, file }, status) => {
        if (meta.status === "done") {
            this.readReviewImage(meta.id, file);
        }
    };

    uploadPreviewImages = (event) => {
        event.preventDefault();
        let me = this;
        me.setState({previewImageLoading: true}, function() {
            API.post('project/' + this.state.workOrder.project.id + '/workorder/' + this.state.workOrder.id + '/uploadpreviewimages', {files : this.state.uploadedPreviewImages}).then(function(response) {
                me.reloadWorkOrder();
            }).catch(function(response) {
                console.log(response);
            });
        });
    };

    uploadReviewImages = (event) => {
        event.preventDefault();
        let me = this;
        API.post('project/' + this.state.workOrder.project.id + '/workorder/' + this.state.workOrder.id + '/uploadreviewimages', {files : this.state.uploadedReviewImages}).then(function(response) {
            me.reloadWorkOrder();
        }).catch(function(response) {
            console.log(response);
        });
    };

    deletePreviewImage = (id) => {
        let me = this;
        me.setState({previewImageLoading: true}, function() {
            API.delete('project/' + this.state.workOrder.project.id + '/workorder/' + this.state.workOrder.id + '/previewimage/' + id).then(function(response) {
                me.reloadWorkOrder();
            }).catch(function() {
                me.setState({previewImageLoading: false});
            });
        });
    };

    deleteReviewImage = (id) => {
        let me = this;
        me.setState({reviewImageLoading: true}, function() {
            API.delete('project/' + me.state.workOrder.project.id + '/workorder/' + me.state.workOrder.id + '/reviewimage/' + id).then(function(response) {
                me.reloadWorkOrder();
            }).catch(function() {
                me.setState({reviewImageLoading: false});
            });
        });
    };

    render() {
        return (
            <Fragment>
                {this.state.workOrder !== null ? (
                    <Breadcrumb parent="Werkbonnen" title={'Detail van werkbon ' + this.state.workOrder.name} />
                ) : null}

                {this.state.workOrder !== null ? (
                    this.state.previewImageLoading || this.state.reviewImageLoading ? (
                        <Loader />
                    ) : (
                            <div className="container-fluid">
                            <div className="user-profile">
                                <Row>
                                    <Col xs={12} sm={12} md={4} lg={4}>
                                        {this.state.workOrder !== null ? (
                                            <WorkOrderProjectInformation workOrder={this.state.workOrder} />
                                        ) : null}
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={8}>
                                        <div className="card firstPost" >
                                            <div className="profile-img-style">
                                                <div className="btn-group btn-group-square" role="group">
                                                    <button className={this.state.editView ? 'btn btn-outline-primary' : 'btn btn-primary'} onClick={this.closeEditView} type="button">Overzicht</button>
                                                    <button className={this.state.editView ? 'btn btn-primary' : 'btn btn-outline-primary'} onClick={this.openEditView} type="button">Wijzig</button>
                                                </div>
                                                <br />
                                                <br />
                                                {this.state.editView ? (
                                                    <div style={{width: '100%'}}>
                                                        <br />
                                                        <form>
                                                            <div className={'form-group row'}>
                                                                <label className={'col-xl-12'}>
                                                                    Naam:
                                                                </label>
                                                                <div className={'col-xl-12'}>
                                                                    <input type={'text'} className={'form-control'} value={this.state.workOrder.name} onChange={this.changeName} />
                                                                </div>
                                                            </div>
                                                            <div className={'form-group row'}>
                                                                <label className={'col-xl-12'}>
                                                                    Beschrijving:
                                                                </label>
                                                                <div className={'col-xl-12'}>
                                                                    <input type={'text'} className={'form-control'} value={this.state.workOrder.description} onChange={this.changeDescription} />
                                                                </div>
                                                            </div>
                                                            {this.state.workOrder.items.map((item, index) => {
                                                                return (
                                                                    <div className={'form-group row'}>
                                                                        <label className={'col-xl-12'}>
                                                                            {item.question}
                                                                        </label>
                                                                        <div className={'col-xl-12'}>
                                                                            {item.type.name === 'text' ? (
                                                                                <textarea type={'text'} onChange={(event) => this.changeAnswerOfQuestion(event, item.id)} className={'form-control'} value={item.answer} />
                                                                            ) : null}
                                                                            {item.type.name === 'multiplechoice' ? (
                                                                                item.options.map((option) => {
                                                                                    return <label className={'form-control'}><input type={'radio'} name={'item_' + item.id} value={option.name} onChange={(event) => this.changeAnswerOfQuestion(event, item.id)} /> {option.name}</label>
                                                                                })
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                            <div className={'form-group row'}>
                                                                <div className={'col-xl-12'}>
                                                                    <a href="#" onClick={this.updateWorkOrder} className={'btn btn-primary'}>Werkbon wijzigen</a>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                ) :(
                                                    <div style={{width: '100%'}}>
                                                        <h4>{this.state.workOrder.name}</h4>
                                                        <h5>Beschrijving:</h5>
                                                        <p>
                                                            {this.state.workOrder.description}
                                                        </p>
                                                        <br />
                                                        <br />
                                                        <h5>Werkbonfiche</h5>
                                                        <table className={'table'}>
                                                            {this.state.workOrder.items.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{_.isEmpty(item.helpingImage) ? null : <img src={item.helpingImage} style={{height: '150px'}}/>}</td>
                                                                        <td>{item.question}</td>
                                                                        <td>
                                                                            {item.answer !== undefined && item.answer !== "" && item.answer !== null ? (
                                                                                <p>{item.answer}</p>
                                                                            ) : (
                                                                                <p>Niets ingevuld</p>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </table>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <WorkOrderTasks workOrder={this.state.workOrder} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        {this.state.workOrder !== undefined && this.state.workOrder !== null ? (
                                            <WorkOrderPhotos workOrder={this.state.workOrder} />
                                        ) : null}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )
                ) : null}
            </Fragment>
        );
    }
};

export default WorkOrderDetail;
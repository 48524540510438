import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import {Link} from "react-router-dom";
import {Col, Input, Row} from "reactstrap";


class UsersOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchInput: "",
            users : [],
            filtered: [],
            columns : [
                {
                    Header : 'Rol',
                    headerClassName : 'text-left',
                    accessor : 'userRole',
                    width: 150,
                    Cell : row => {
                        const role = row.row.userRole;
                        if (role === 'ADMIN') {
                            return (<><i className="icofont icofont-2x icofont-court" title={'Beheerder'}></i> Beheerder</>)
                        } else if (role === 'EMPLOYEE') {
                            return (<><i className="icofont icofont-2x icofont-waiter" title={"Medewerker"}></i> Medewerker</>)
                        } else if (role === 'EMPLOYER') {
                            return (<><i className="icofont icofont-2x icofont-user-suited" title={"Werkgever"}></i> Zaakeigenaar</>)
                        } else if (role === 'CUSTOMER') {
                            return (<><i className="icofont icofont-2x icofont-user" title={"Klant"}></i> Klant</>)
                        } else {
                            return (<><i className="icofont icofont-2x icofont-user" title={"Klant"}></i> Klant</>)
                        }
                    }
                },
                {
                    Header : 'Naam',
                    headerClassName : 'text-left',
                    accessor : 'firstName',
                    Cell : row => {
                        return <>{row.row._original.firstName} {row.row._original.lastName}</>
                    }
                },
                {
                    Header : 'Gebruikersnaam',
                    headerClassName : 'text-left',
                    accessor : 'username'
                },
                {
                    Header : 'Postcode',
                    headerClassName : 'text-left',
                    accessor : 'postalCode',
                    width: 80
                },
                {
                    Header : 'Gemeente',
                    headerClassName : 'text-left',
                    accessor : 'city'
                },
                {
                    Header : 'E-mailadres',
                    headerClassName : 'text-left',
                    accessor : 'primaryEmail'
                },
                {
                    Header : 'Telefoon',
                    width: 150,
                    headerClassName : 'text-left',
                    accessor : 'primaryPhone'
                },
                {
                    Header : 'GSM',
                    width: 150,
                    headerClassName : 'text-left',
                    accessor : 'primaryMobile'
                },
                {
                    Header : '',
                    headerClassName : 'text-left',
                    accessor : 'id',
                    width: 50,
                    Cell: row => {
                        return <Link to={`/user/${row.row.id}/detail`} target={'_parent'} style={{color: process.env.REACT_APP_STYLING_CARD_FONT_COLOR}}><i className="icofont icofont-2x icofont-pencil-alt-1" title={"Bekijk"}></i></Link>
                    }
                }

            ]
        };
    }

    componentDidMount() {
        var me = this;
        API.get('users').then(function(response) {
            me.setState({users: response.data});
            me.setState({filtered: response.data});
        });
    }

    handleChange = event => {
        this.setState({ searchInput: event.target.value }, () => {
            this.globalSearch();
        });
    };

    globalSearch = () => {
        let { searchInput, users } = this.state;
        let filteredData = users.filter(value => {
            return (
                value.firstName.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.lastName.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.username.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.postalCode.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.city.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.street.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.houseNumber.toLowerCase().includes(searchInput.toLowerCase()) ||
                ((value.country !== null && value.country !== undefined) ? value.country.name.toLowerCase().includes(searchInput.toLowerCase()) : false)
            );
        });
        this.setState({ filtered: filteredData });
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb>Gebruikersbeheer</Breadcrumb>
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row">
                           <div className="col-lg-12">
                               <Input
                                   size="large"
                                   name="searchInput"
                                   value={this.state.searchInput || ""}
                                   onChange={this.handleChange}
                                   label="Search"
                                   placeholder={'Zoeken'}
                               />
                               <br />
                               <br />
                               <ReactTable onChange={this.componentDidMount}
                                   data={this.state.filtered}
                                   columns={this.state.columns}
                                   defaultPageSize={10}
                                   className={"-striped -highlight"}
                                   showPagination={true}
                                   previousText={'Vorige'}
                                   nextText={'Volgende'}
                                   pageText={'Pagina'}
                                   rowsText={'rijen'}
                                   ofText={'van'}
                                   getTheadThProps={() => ({
                                    style: {
                                        fontWeight: '600',
                                        height: '50px',
                                        color: process.env.REACT_APP_STYLING_CARD_FONT_COLOR,
                                        backgroundColor: process.env.REACT_APP_STYLING_CARD_BACKGROUND_COLOR,
                                        borderLeft: '0px',
                                        borderRight: '0px',
                                        textTransform: 'uppercase'
                                    },
                                  })}
                                    getTdProps={() => ({
                                        style: {
                                            height: '50px',
                                            backgroundColor: process.env.REACT_APP_STYLING_CARD_BACKGROUND_COLOR,
                                            color: process.env.REACT_APP_STYLING_CARD_FONT_COLOR + ' !important',
                                            borderLeft: 0,
                                            borderRight: 0,
                                            borderTop: 0,
                                            borderBottom: 0
                                        },
                                      })}
                                    getTrProps={() => ({
                                        style: {
                                            height: '50px',
                                            backgroundColor: process.env.REACT_APP_STYLING_CARD_BACKGROUND_COLOR,
                                            color: process.env.REACT_APP_STYLING_CARD_FONT_COLOR,
                                            borderLeft: 0,
                                            borderRight: 0,
                                            borderTop: 0,
                                            borderBottom: 0
                                        }
                                      })}
                                    getPaginationProps={() => ({
                                        style: {
                                            backgroundColor: process.env.REACT_APP_STYLING_CARD_BACKGROUND_COLOR,
                                            color: process.env.REACT_APP_STYLING_CARD_FONT_COLOR
                                        } 
                                      })}
                               />
                            </div>
                        </div>
                        <br />
                        <Row>
                            <Col lg={4}>
                                <Link className={'btn btn-primary form-control pt-3 pb-3'} to={'/users/create'}><h2><i className="icofont icofont-2x icofont-user" title={"Klant"}></i></h2>Klant aanmaken</Link>
                            </Col>
                            <Col lg={4}>
                                <Link className={'btn btn-primary form-control pt-3 pb-3'} to={'/employee/create'}><h2><i className="icofont icofont-2x icofont-waiter" title={"Klant"}></i></h2>Medewerker aanmaken</Link>
                            </Col>
                            <Col lg={4}>
                                <Link className={'btn btn-primary form-control pt-3 pb-3'} to={'/employer/create'}><h2><i className="icofont icofont-2x icofont-user-suited" title={"Klant"}></i></h2>Beheerder aanmaken</Link>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default UsersOverview;
import React, { Fragment, useState, useMemo, useEffect, useRef } from 'react';
import ReactTable from "react-table-6";
import { Button, Col, Row} from "reactstrap";
import API from '../../../utils/API';
import _ from "lodash";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import useReactTable from '../../../hooks/useReactTable';
import Select from 'react-select';
import Loader from '../../common/loader';
import { NaturePeopleOutlined } from '@material-ui/icons';

const ProjectTasks = (props) =>  {
    const ref = useRef(null);
    const history = useHistory();
    const [project, setProject] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorOccurred, setErrorOccurred] = useState(false);
    const [users, setUsers] = useState([]);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [newTask, setNewTask] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [taskExecutors, setTaskExecutors] = useState([]);
    const [taskResponsibles, setResponsibles] = useState([]);
    const columns = useMemo(() => [
        {
            Header : 'Taak',
            accessor : 'name',
            headerClassName: "text-left",
            width: 250,
            Cell: row => {
                return <Link to={`/task/detail/` + row.row._original.id} target={'_parent'}>{row.row.name}</Link>
            }
        },{
            Header : 'Begin',
            accessor : 'plannedDateForExecution',
            headerClassName: "text-left",
            width: 250,
            Cell: row => {
                if (row.row.plannedDateForExecution != null) {
                    return new Intl.DateTimeFormat("nl-BE", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit"
                    }).format(new Date(row.row.plannedDateForExecution));
                } else {
                    return "Nog te bepalen";
                }
            }
        },{
            Header : 'Einde',
            accessor : 'plannedEndDateForExecution',
            headerClassName: "text-left",
            width: 250,
            Cell: row => {
                if (row.row.plannedEndDateForExecution != null) {
                    return new Intl.DateTimeFormat("nl-BE", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit"
                    }).format(new Date(row.row.plannedEndDateForExecution));
                } else {
                    return "Nog te bepalen";
                }
            }
        },
        {
            Header : 'Uitvoerder',
            accessor : 'responsibleUser',
            headerClassName: "text-left",
            Cell: row => {
                if (row.row.responsibleUser !== undefined && row.row.responsibleUser !== null) {
                    return <span>{row.row.responsibleUser.firstName} {row.row.responsibleUser.lastName}</span>
                } else {
                    console.log(row.row);
                    if (row.row._original.responsibleUsers !== undefined && row.row._original.responsibleUsers !== null) {
                        return <>
                            {row.row._original.responsibleUsers.map((responsibleUser, index) => {
                                return <>{index != 0 ? ', ' : null}{responsibleUser.firstName + ' ' + responsibleUser.lastName}</>
                            })}
                        </>
                    } else {
                        return <span></span>
                    }
                }
            }
        },
        {
            Header : 'Status',
            accessor : 'status',
            headerClassName: "text-left",
            Cell: row => {
                return <span>{row.row.status}</span>
            }
        },
        {
            Header : 'Geëvalueerd',
            accessor : 'signedAt',
            headerClassName: "text-left",
            Cell: row => {
                if (row.row.signedAt !== undefined && row.row.signedAt !== null) {
                    return <span>{new Intl.DateTimeFormat('nl-BE', {dateStyle: 'medium'}).format(Date.parse(row.row.signedAt))}</span>
                } else {
                    return <span>-</span>
                }
            }
        },
        {
            Header : 'Acties',
            accessor : 'id',
            headerClassName: "text-left",
            Cell: row => {
                return (<>
                    <Link to={`/task/detail/` + row.row.id} target={'_parent'}><span style={{fontSize: '1.5em'}} className='icofont ico icofont-ui-edit'></span></Link>
                    &nbsp;&nbsp;
                    <Link to={`/task/detail/` + row.row.id + '/evaluate'} target={'_parent'}><span style={{fontSize: '1.5em'}} className='icofont icofont-ui-clip-board'></span></Link>
                    &nbsp;&nbsp;
                    <a href={'#'} onClick={() => deleteTask(row.row.id)}><span style={{fontSize: '1.5em'}} className='icofont icofont-ui-close'></span></a>
                </>);
            }
        }
    ]);

    const openSegmentToAddTask = () => {
        setErrorOccurred(false);
        setShowCreateDialog(true);
    };

    const closeSegmentToAddTask = () => {
        setShowCreateDialog(false);
        setErrorOccurred(false);
    };

    const deleteTask = (id) => {
        API.delete('/task/' + id).then(function() {
            API.get('/project/' + project.id +'/tasks').then(function(response) {
                setTasks(response.data);
                setShowCreateDialog(false);
            });
        });
        
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setNewTask({...newTask, [name]: value});
    };

    const handleChangeCheckbox =  (e) => {
        const {name, value} = e.target;
        setNewTask({...newTask, [name]: value === 'on'});
    };

    const changeTaskAccountableUser = (event) => {
        let value = event.target.value;
        setNewTask({...newTask, accountableUserId: value});
    };

    const changeTaskResponsibleUser = (choice) => {
        let arr = [];
        for (let key in choice) {
            arr.push(choice[key].value);
        }
        setNewTask({...newTask, responsibleUserIds: arr});
    };

    const validateTask = () => {
        let validated = true;
        let errors = [];
        if (newTask.name === "" || newTask.name === null || newTask.name === undefined ) {
            validated = false;
            errors.push("Naam is een verplicht veld");
        }
        if (newTask.description === "" || newTask.description === null || newTask.description === undefined ) {
           validated = false;
           errors.push("Beschrijving is een verplicht veld");
        }
        if (newTask.responsibleUserIds === null || newTask.responsibleUserIds === undefined || newTask.responsibleUserIds.length == 0 ) {
            validated = false;
            errors.push("Er moet minstens 1 uitvoerder ingegeven worden");
        }
        if (newTask.accountableUserId === "" || newTask.accountableUserId === null || newTask.accountableUserId === undefined) {
            validated = false;
            errors.push("Er moet exact 1 verantwoordelijke ingegeven worden");
        }
        setFormErrors(errors);
        return validated;
    };

    const addNewTask = () => {
        if(validateTask()) {
            setIsLoading(true);
            API.post('project/' + project.id +  '/task', newTask).then(function(response) {
                API.get('/project/' + project.id +'/tasks').then(function(response) {
                    setTasks(response.data);
                    setShowCreateDialog(false);
                    setIsLoading(false);
                });
            }).catch(function(response) {
                setIsLoading(false);
                setErrorOccurred(true);
            });
        } 
    };

    const cancelAddTaskToProject = () => {
        setShowCreateDialog(false);
    };

    useEffect(() => {
        setProject(props.project);
        API.get('users/workers').then(function(response) {
            setUsers(response.data);
        });
    }, []);

    useEffect(() => {
        if (users.length > 0) {
            let executorsArray = [];
            let reponsiblesArray = [];
            for (let userKey in users) {
                executorsArray.push({value: users[userKey].id, label: users[userKey].firstName + " " + users[userKey].lastName});
                reponsiblesArray.push({value: users[userKey].id, label: users[userKey].firstName + " " + users[userKey].lastName});
            }
            setTaskExecutors(executorsArray);
            setResponsibles(reponsiblesArray);    
        }
    }, [users]);

    useEffect(() => {
        if (project !== null) {
            API.get('/project/' + project.id +'/tasks').then(function(response) {
                setTasks(response.data);
            });
            
        }
    }, [project]);

    return (
        <>
            <Row className={'mt-3'}>
                <Col lg={12}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <br />
                            {project && tasks !== undefined ? (
                                <ReactTable 
                                    data={tasks}
                                    columns={columns}
                                    minRows={0}
                                    defaultPageSize={10}
                                    className={"-striped -highlight"}
                                    showPagination={true}
                                    previousText={'Vorige'}
                                    nextText={'Volgende'}
                                    pageText={'Pagina'}
                                    rowsText={'rijen'}
                                    ofText={'van'}
                                    getTheadThProps={getTheadThProps}
                                    getTdProps={getTdProps}
                                    getTrProps={getTrProps}
                                    getPaginationProps={getPaginationProps}
                                />
                            ) : (
                                <p>Er zijn geen offerten gedefinieerd voor dit project.</p>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className={'mt-1'}>
                <Col lg={12}>
                {project !== null && project.status !== 'FINISHED' ? (
                    <div>
                        <br />
                        <a href={'/project/' + project.id +  '/task/create'} className={'btn btn-primary'} ><span className={'fa fa-plus'}></span> Nieuwe taak toevoegen</a>
                    </div>
                ) : null}
                </Col>
            </Row>
        </>
    );
};

export default ProjectTasks;